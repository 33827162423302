import { graphql } from 'gatsby';
import React from 'react';
import LitStructuredText from './generic/LitStructuredText';

const ModularSimpleContent = ({ data, slug }) => {
  const { backgroundColor, content, largeText } = data;
  return (
    <div
      className={`modular-simple-content-container ${
        slug === 'legal' ? backgroundColor : 'White'
      }`}
    >
      <div className="modular-p modular-heading-third modular-heading-secondary modular-global-width">
        <div className={`${largeText && 'modular-large-text'}`}>
          <LitStructuredText data={content} basic />
        </div>
      </div>
    </div>
  );
};

export default ModularSimpleContent;
export const fragment = graphql`
  fragment ModularSimpleContent on DatoCmsSimpleModularContentBlock {
    id
    internal {
      type
    }
    backgroundColor
    largeText
    content {
      value
      links {
        __typename
        ... on DatoCmsBlogArticleModel {
          id: originalId
          slug
        }
        ... on DatoCmsNewsArticleModel {
          id: originalId
          slug
        }
        ... on DatoCmsModularPage {
          id: originalId
          slug
        }
        ... on DatoCmsSimpleWebsitePageModel {
          id: originalId
          slug
        }
      }
    }
  }
`;
