import React, { useState, useRef } from 'react';
import { StructuredText } from 'react-datocms/structured-text';
import LitImage from '../images/LitImage';
import {
  StructuredTextOptions,
  BasicStructuredTextOptions,
} from '../templates/Styles';
import { Link } from 'gatsby';
import Modal from '../Modal';
import PostcodeSearch from '../PostcodeSearch';
import HumanHelpModal from '../HumanHelpModal';
import RegisterInterestModal from '../RegisterInterestModal';
import SimplePagePopUp from '../SimplePagePopUp';
const LitStructuredText = ({ data, key, basic = false }) => {
  const options = basic ? BasicStructuredTextOptions : StructuredTextOptions;
  const [selectedAddress, setSelectedAddress] = useState(undefined);
  const [inputDisplayValue, setInputDisplayValue] = useState('');

  const sanitizeSlug = (slug) => (slug.startsWith('/') ? slug : '/' + slug);

  const GetInlineBlock = ({ record, children }) => {
    const [dialogShow, setDialogShow] = useState(true);
    const [animation, setAnimation] = useState(false);
    const modal = useRef(null);
    const switchDialog = () => {
      const dialog = modal.current;
      setDialogShow((prevState) => !prevState);
      if (dialogShow) {
        dialog.showModal();
        setAnimation('open-modal');
      } else {
        setAnimation('close-modal');
        setTimeout(() => {
          dialog.close();
        }, 200);
      }
    };
    switch (record?.__typename) {
      case 'DatoCmsSimplePageModal':
        return (
          <>
            <button
              onClick={switchDialog}
              className={`${record?.modalTextColor}-button-underline`}
            >
              {record?.modalText}
            </button>
            <Modal
              animation={animation}
              dialogShow={dialogShow}
              modal={modal}
              switchDialog={switchDialog}
            >
              <div className="modal-simple-website">
                <SimplePagePopUp
                  simpleModularContent={record?.simplePage.simpleModularContent}
                />
              </div>
            </Modal>
          </>
        );
      case 'DatoCmsPostcodeCheckerCallToAction':
        if (record.isModal) {
          return (
            <>
              <button
                className="button-blue modal-button"
                onClick={switchDialog}
              >
                {record?.buttonText}
              </button>
              <Modal
                animation={animation}
                dialogShow={dialogShow}
                modal={modal}
                switchDialog={switchDialog}
              >
                <h3 className="modal-header">Check if you can get Lit:</h3>
                <div className="PostcodeSearchPrimary">
                  <PostcodeSearch
                    placeholder="Check availability"
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    inputDisplayValue={inputDisplayValue}
                    setInputDisplayValue={setInputDisplayValue}
                  />
                </div>
              </Modal>
            </>
          );
        } else {
          return <Link to={'/get-lit'}>{record?.buttonText}</Link>;
        }
      case 'DatoCmsInlineIdContent':
        return <div id={record?.idContent}>{record?.text}</div>;
      case 'DatoCmsYoutubeLink':
        return (
          <div
            style={{
              width: '100%',
              paddingBottom: '56.25%',
              position: 'relative',
              display: 'block',
            }}
          >
            <iframe
              src={`https://www.youtube.com/embed/${record?.path}`}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: '0',
                left: '0',
              }}
              frameborder="0"
              allowfullscreen=""
            ></iframe>
          </div>
        );
      case 'DatoCmsPath':
        return record?.isRegisterInterestModal ? (
          <RegisterInterestModal
            isLink={record?.isLink}
            isButton={record?.isBlock}
            text={record?.pathText}
            pathColour={record.pathColour}
          />
        ) : record?.isContactModalButton ? (
          <HumanHelpModal
            image={record?.image}
            text={record?.pathText}
            isLink={true}
            pathColour={record.pathColour}
          />
        ) : record?.isContactModalSquareButton ? (
          <HumanHelpModal
            image={record?.image}
            text={record?.pathText}
            isButton={true}
            pathColour={record.pathColour}
          />
        ) : (
          <Link to={record?.pathContent} className={`path`}>
            <div className="image">
              <LitImage image={record?.image} />
            </div>
            {record?.pathText}
          </Link>
        );
    }
  };
  const GetInlineRecord = ({ record }) => {
    switch (record?.__typename) {
      case 'DatoCmsMediaModel':
        return record?.media.mimeType === 'video/mp4' ? (
          <video
            autoPlay
            loop
            playsInline
            muted
            style={{ width: '100%', height: '100%' }}
          >
            <source src={record?.media.url} type={record?.media.mimeType} />
          </video>
        ) : (
          <LitImage image={record?.media} />
        );
      case 'DatoCmsMediaText':
        if (record?.mediaImage) {
          return (
            <div className="media-text-container">
              <div className="media">
                <LitImage image={record?.mediaImage} />
              </div>
              <div className="text">
                <LitStructuredText data={record?.header} />
                <LitStructuredText data={record?.content} />
              </div>
            </div>
          );
        }
    }
    return null;
  };

  const GetLinkToRecord = ({ record, children, transformedMeta }) => {
    if (record?.media?.url) {
      return (
        <Link to={record.media.url} target={transformedMeta?.target}>
          {children}
        </Link>
      );
    }

    if (record?.slug) {
      let url = undefined;
      switch (record.__typename) {
        case 'DatoCmsSimpleWebsitePageModel':
          url = sanitizeSlug(record.slug);
          break;
        case 'DatoCmsModularPage':
          url = sanitizeSlug(record.slug);
          break;
        case 'DatoCmsBlogArticleModel':
          url = '/blog' + sanitizeSlug(record.slug);
          break;
        case 'DatoCmsNewsArticleModel':
          url = '/news' + sanitizeSlug(record.slug);
          break;
      }
      if (url) {
        return (
          <Link to={url} target={transformedMeta?.target}>
            {children}
          </Link>
        );
      }
    }

    return children;
  };

  return data?.value ? (
    <StructuredText
      data={data}
      key={key}
      {...options}
      renderInlineRecord={GetInlineRecord}
      renderLinkToRecord={GetLinkToRecord}
      renderBlock={GetInlineBlock}
    />
  ) : null;
};

export default LitStructuredText;
